import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import SocialIcons from '../common/SocialIcons'

import '../../styles/components/hero-big.scss'
import { Link } from 'gatsby'

class HeroBig extends React.Component {
  render() {
    return (
      <article className="hero dec-section--box-top dec-section--box-bottom">
        <div className="container">
          <div className="row">
            <div className="col">
                <SocialIcons type={"light"} position={"relative"} />
                <div className="hero__header">
                  <h1>{this.props.headerTitle}</h1>
                  <p>{this.props.headerSubtitle}</p>
                </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center hero__cta">
            <div className="col-md-6">
              <div className="hero__cta-image">
                <GatsbyImage image={this.props.ctaImage} loading="auto" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="hero__cta-content">
                <h2>{this.props.ctaTitle}</h2>
                <p dangerouslySetInnerHTML={{ __html: this.props.ctaText }} />
                {this.props.ctaButton
                  ? <Link to="/about-us/" className="btn--more">{this.props.ctaButton}&nbsp;<span>&rarr;</span></Link>
                  : null
                }
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default HeroBig